<template>
  <div class="d-flex flex-column h-100">
    <p class="title">Ubicación</p>
    <quick-message
      class="mt-3"
      with-icon
      :show.sync="hasErrors"
      :message="message"
      type="error"
    />
    <div
      v-if="!editMode"
      class="d-flex flex-column h-100 justify-sm-content-around"
    >
      <div class="z-section">
        <p>{{ userAddress.address }}</p>
      </div>
      <div class="mt-4">
        <z-map
          id="address-map"
          reverse-geocode
          :destinations="mapAddress"
          :center="mapCenter"
          @decoded-position="handleAddressChange"
        />
      </div>
      <z-button class="z-action" @click="clearForm(true)">
        Editar
      </z-button>
    </div>
    <div v-else class="d-flex flex-column">
      <form-default-address
        :position="userAddress.position"
        :default-address="userAddress"
        :address="draftAddress"
        :reference="draftReference"
        @address-change="handleAddressChange"
        @references-change="handleReferencesChange"
      />
      <div class="d-flex flex-column flex-sm-row">
        <z-button
          variant="secondary"
          class="z-action w-auto mr-sm-3"
          @click="clearForm(false)"
        >
          Cancelar
        </z-button>
        <z-button
          :disabled="isLoading || !isValidForm"
          :loading="isLoading"
          class="z-action w-auto mt-3 mt-sm-0"
          @click="editAddress"
        >
          Guardar cambios
        </z-button>
      </div>
    </div>
  </div>
</template>

<script>
import ZMap from "@zubut/common/src/components/ZMap";
import FormDefaultAddress from "@/app/components/FormDefaultAddress";

export default {
  name: "CardDetailAddress",

  components: {
    ZMap,
    FormDefaultAddress
  },

  props: {
    userAddress: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      editMode: false,
      hasErrors: false,
      message: "",
      draftAddress: null,
      draftReference: ""
    };
  },

  computed: {
    mapCenter() {
      return [
        this.userAddress?.position?.lat ?? 20.6737777,
        this.userAddress?.position?.lng ?? -103.4054536
      ];
    },
    mapAddress() {
      return this.userAddress.position ? [this.userAddress] : [];
    },

    validAddress() {
      return this.draftAddress?.address?.length > 0;
    },
    isValidForm() {
      return this.validAddress;
    }
  },

  watch: {
    editMode(newVal) {
      this.$emit("expanded", newVal);
    }
  },

  methods: {
    clearForm(edit) {
      this.editMode = edit;
      this.draftAddress = this.userAddress;
      this.draftReference = this.userAddress.information;
    },

    editAddress() {
      this.isLoading = true;
      const data = {
        ...this.draftAddress,
        information: this.draftReference
      };
      this.$store
        .dispatch(`clients/editAddress`, {
          addressId: this.userAddress.id,
          data
        })
        .then(() => {
          this.$emit("update:user-address", {
            ...this.draftAddress,
            information: this.draftReference
          });
          this.clearForm();
        })
        .catch(err => {
          this.message = err?.message || "Error al actualizar dirección";
          this.hasErrors = true;
          setTimeout(() => {
            this.hasErrors = false;
          }, 5000);
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleAddressChange(address) {
      this.draftAddress = { ...this.userAddress, ...address };
    },

    handleReferencesChange(references) {
      this.draftReference = references;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: 600;
}

.z-action {
  font-weight: 500;
  height: 40px;
  width: 100px;
}

.z-section {
  color: $black;

  p {
    font-size: 12px;
    margin: 0;
  }
}

#address-map {
  height: 200px;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 10px;
  overflow: hidden;
}
</style>
