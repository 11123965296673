<template>
  <collapsable-card :visible.sync="showRatesPackages">
    <template #header>
      <div class="font-weight-semi-bold d-flex align-items-center">
        <service-type-avatar :type="serviceTypeNumber" size="md" class="mr-3" />
        Rutas
      </div>
    </template>
    <b-form>
      <rates-layout>
        <template v-slot:left>
          <z-input
            id="motorcycle-delivered-rate"
            v-model="bikeDelivery"
            label="Motocicleta (Entregado)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ bikeDelivery })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(bikeDelivery)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="motorcycle-attempt-rate"
            v-model="bikeAttempt"
            label="Motocicleta (Intento)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ bikeAttempt })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(bikeAttempt)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="car-delivered-rate"
            v-model="carDelivery"
            label="Automóvil (Entregado)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ carDelivery })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(carDelivery)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="car-attempt-rate"
            v-model="carAttempt"
            label="Automóvil (Intento)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ carAttempt })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(carAttempt)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <z-button
        v-show="!editMode"
        class="mt-3"
        size="lg"
        @click="enableEditMode"
      >
        Editar
      </z-button>
      <z-button
        v-show="editMode"
        :loading="loading"
        class="mt-3"
        size="lg"
        @click="saveForm"
      >
        Guardar
      </z-button>
      <z-button
        v-show="editMode"
        class="mt-3 ml-3"
        variant="secondary"
        size="lg"
        :disabled="loading"
        @click="cancel"
      >
        Cancelar
      </z-button>
    </b-form>
  </collapsable-card>
</template>

<script>
import RatesLayout from "./RatesLayout.vue";
import Clients from "@/services/clients";
import RatesPackages from "@/services/rates-packages";
import ConsRatesPackages from "@/constants/rates/packages";
import { numberFormatter } from "@/utils/strings";
import ratesUtils from "@/mixins/ratesUtils";
import validateStateMixin from "@/mixins/validateState";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";

export default {
  name: "RatesPackages",

  components: { RatesLayout, CollapsableCard, ServiceTypeAvatar },

  mixins: [ratesUtils, validateStateMixin],

  props: {
    clientId: {
      type: String,
      required: true
    },
    serviceTypeNumber: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      editMode: false,
      rateId: null,
      bikeDelivery: null,
      bikeAttempt: null,
      carDelivery: null,
      carAttempt: null,
      backup: {},
      showRatesPackages: true
    };
  },

  computed: {
    disableFields() {
      return !this.editMode;
    }
  },

  beforeMount() {
    this.getRatesPackages();
  },

  methods: {
    getRatesPackages() {
      Clients.getRatesPackages(this.clientId)
        .then(res => {
          this.rateId = res.id;
          this.bikeDelivery = res.bikeDelivery;
          this.bikeAttempt = res.bikeAttempt;
          this.carDelivery = res.carDelivery;
          this.carAttempt = res.carAttempt;
        })
        .catch(error => {
          if (error.statusCode === 404) {
            this.setDefaultValues();
          } else {
            this.$captureError(error);
          }
        });
    },
    setDefaultValues() {
      this.bikeDelivery = ConsRatesPackages.DELIVERED_MOTORCYCLE;
      this.bikeAttempt = ConsRatesPackages.ATTEMPT_MOTORCYCLE;
      this.carDelivery = ConsRatesPackages.DELIVERED_CAR;
      this.carAttempt = ConsRatesPackages.ATTEMPT_CAR;
    },
    cancel() {
      this.editMode = false;
      this.restoreForm();
    },
    enableEditMode() {
      this.editMode = true;
      this.backupForm();
    },
    backupForm() {
      this.backup.bikeDelivery = this.bikeDelivery;
      this.backup.bikeAttempt = this.bikeAttempt;
      this.backup.carDelivery = this.carDelivery;
      this.backup.carAttempt = this.carAttempt;
    },
    restoreForm() {
      this.bikeDelivery = this.backup.bikeDelivery;
      this.bikeAttempt = this.backup.bikeAttempt;
      this.carDelivery = this.backup.carDelivery;
      this.carAttempt = this.backup.carAttempt;
    },
    saveForm() {
      if (!this.rateId) {
        this.createRate();
      } else {
        this.editRate();
      }
    },
    createRate() {
      this.loading = true;
      RatesPackages.createRatesPackages(this.formatRequest())
        .then(res => {
          this.rateId = res.id;
          this.backupForm();
          this.cancel();
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editRate() {
      this.loading = true;
      RatesPackages.updateRatesPackages(this.rateId, this.formatRequest())
        .then(res => {
          this.backupForm();
          this.cancel();
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatRequest() {
      return {
        bikeDelivery: this.floatNumber(this.bikeDelivery),
        bikeAttempt: this.floatNumber(this.bikeAttempt),
        carDelivery: this.floatNumber(this.carDelivery),
        carAttempt: this.floatNumber(this.carAttempt),
        clientId: this.clientId
      };
    },
    numberFormatter(value) {
      if (value) {
        const parsedVal = parseFloat(this.removeCommas(value));
        return numberFormatter(parsedVal);
      }
      return "";
    },
    floatNumber(value) {
      if (typeof value === "string") {
        return parseFloat(this.removeCommas(value));
      }
      return value;
    },
    removeCommas(value) {
      return value.replace(/,/g, "");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/z-input-group.scss";
</style>
