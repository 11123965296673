<template>
  <div
    v-if="showWallet"
    class="activate-wallet position-relative"
    :class="{ 'size-sm': size === 'sm', 'size-lg': size === 'lg' }"
    :style="`background-image: url('${walletBackgroundImage}');`"
  >
    <div
      class="d-sm-flex justify-content-sm-between"
      :class="clabe ? 'align-items-sm-start' : 'align-items-sm-center'"
    >
      <div class="mb-md-0">
        <div class="wallet-title font-weight-semi-bold text-lg">
          Zubut Wallet
        </div>
        <div class="balance-amount font-weight-semi-bold text-2xl">
          {{ formattedAmount ? formattedAmount : "$0.00" }}
        </div>
        <div
          v-if="clabe"
          class="clabe-text text-lavender-blue text-uppercase font-weight-semi-bold mb-1"
        >
          <p>
            Clabe <span class="d-block">{{ clabe }}</span>
          </p>
        </div>
      </div>
      <div class="d-sm-flex justify-content-end">
        <div class="d-flex align-items-center">
          <span class="d-sm-none">Refrescar</span>
          <button
            class="btn btn-link align-self-center wallet-button"
            @click="updateWallet"
          >
            <z-icon
              :class="loading ? 'animation-spin' : null"
              name="WalletRefresh"
              color="#FFFFFF"
            />
          </button>
          <z-button
            variant="secondary"
            text-color="primary"
            @click="showModifyWallet = true"
          >
            Modificar
          </z-button>
        </div>
      </div>
    </div>
    <modify-wallet-amount
      :show.sync="showModifyWallet"
      :selected-wallet="wallet"
      @updated-amount="handleUpdate"
    />
  </div>
</template>

<script>
import Client from "@/services/clients";
import { formatMoney } from "@zubut/common/src/utils/money";
import ModifyWalletAmount from "@/app/components/ModifyWalletAmount";
import currency from "currency.js";

export default {
  name: "WalletBalance",

  components: {
    ModifyWalletAmount
  },

  props: {
    size: {
      type: String,
      default: "lg",
      validator: val => ["sm", "lg"].indexOf(val) > -1
    },
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      walletBackgroundImage: require("@/assets/img/wallet-background.png"),
      walletAmount: null,
      walletId: null,
      wallet: null,
      clabe: null,
      loading: false,
      activating: false,
      showModifyWallet: false
    };
  },

  computed: {
    buttonSize() {
      if (this.size === "sm") {
        return "sm";
      }
      return "md";
    },
    showWallet() {
      return this.walletId;
    },
    formattedAmount() {
      return formatMoney(this.wallet.amount);
    }
  },

  mounted() {
    this.getWallletData();
  },

  methods: {
    getWallletData() {
      this.loading = true;
      Client.getWallletData(this.user.id)
        .then(res => {
          this.wallet = res;
          this.wallet.amount = currency(this.wallet.amount).divide(100);
          this.clabe = res.clabeReference;
          this.walletId = res.id;
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },

    updateWallet() {
      this.getWallletData();
    },

    handleUpdate(amount) {
      this.wallet.amount = amount;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@zubut/common/src/styles/animation/spin.scss";
p {
  margin: 0;
}
.activate-wallet {
  color: $white;
  border-radius: 12px;
  background-color: #0059bf;
  background-position: bottom -50px right;
  background-repeat: no-repeat;
  background-size: 150%;
  cursor: pointer;

  &.size-sm {
    padding: 16px;

    .balance-amount {
      font-size: 1.857rem;
    }
    .balance-title,
    .wallet-title {
      font-size: 1rem;
    }

    .clabe-text {
      margin-top: 6px;
    }
  }

  &.size-lg {
    padding: 24px;
  }

  button {
    width: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .activate-wallet button {
    width: auto;
  }

  .balance-title {
    text-align: right;
  }
}

.wallet-button {
  display: flex;
  align-items: center;
  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: none;
  }
}

@include media-breakpoint-up(md) {
  .activate-wallet.size-lg {
    .clabe-text {
      margin-top: 14px;
    }
  }
}
</style>
