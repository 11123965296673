<template>
  <z-modal :show="show" @hide="hideModal">
    <client-link-branch @hide="hideModal" @branch-linked="handleBranchLinked" />
  </z-modal>
</template>

<script>
import ZModal from "@zubut/common/src/components/ZModal";
import ClientLinkBranch from "./ClientLinkBranch";

export default {
  name: "ClientLinkBranchDesktop",

  components: {
    ZModal,
    ClientLinkBranch
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    hideModal() {
      this.$emit("update:show", false);
    },
    handleBranchLinked(addr) {
      this.$emit("branch-linked", addr);
    }
  }
};
</script>

<style lang="scss" scoped></style>
