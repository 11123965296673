import currency from "currency.js";
export default {
  methods: {
    calculateIVA(quantity) {
      const subtotal = currency(quantity || 0).value;
      return currency(subtotal).multiply(0.16).value;
    },
    formatNumberOnBlur(valueObject) {
      const firstKey = Object.keys(valueObject)[0];
      const num = valueObject[firstKey];

      this[firstKey] = this.numberFormatter(num);
    }
  }
};
