<template>
  <div>
    <z-modal
      :show="show"
      centered
      size="md"
      body-class="p-0 rounded"
      @hide="hideModal"
    >
      <form-create-client
        selection-disabled
        @close="hideModal"
        @branch-created="handleBranchCreated"
      />
    </z-modal>
  </div>
</template>

<script>
import ClientTypes from "@zubut/common/src/constants/clients/type";
import ZModal from "@zubut/common/src/components/ZModal";
import FormCreateClient from "@/app/views/ClientCreate/FormCreateClient";
import * as mutation from "@/store/modules/clients/mutations-types";

export default {
  name: "ClientCreateBranchDesktop",

  components: {
    ZModal,
    FormCreateClient
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    parentCompany: {
      type: String,
      required: true
    }
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.$store.commit(
          `clients/${mutation.SET_CLIENT_TYPE}`,
          ClientTypes.NUM_BRANCH
        );
        this.$store.commit(
          `clients/${mutation.SET_PARENT_COMPANY}`,
          this.parentCompany
        );
      } else {
        this.$store.commit(
          `clients/${mutation.SET_CLIENT_TYPE}`,
          ClientTypes.NUM_CLIENT
        );
        this.$store.commit(`clients/${mutation.SET_PARENT_COMPANY}`, null);
      }
    }
  },

  methods: {
    hideModal() {
      this.$emit("update:show", false);
    },
    handleBranchCreated(client) {
      this.$emit("branch-created", client);
    }
  }
};
</script>

<style lang="scss" scoped></style>
