<template>
  <collapsable-card :visible.sync="showRatesReservations">
    <template #header>
      <div class="font-weight-semi-bold d-flex align-items-center">
        <service-type-avatar :type="serviceTypeNumber" size="md" class="mr-3" />
        Renta por hora
      </div>
    </template>
    <b-form>
      <hr />
      <p><span class="span-bold">Entre semana </span>Horario: 9 am - 10 pm</p>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="motorcycle-max-rate-work-week"
            v-model="bikeHourWeekRange1"
            label="Motocicleta (De 4 a 30 hrs)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ bikeHourWeekRange1 })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(bikeHourWeekRange1)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="motorcycle-min-rate-work-week"
            v-model="bikeHourWeekRange2"
            label="Motocicleta (Mas de 31 hrs)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ bikeHourWeekRange2 })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(bikeHourWeekRange2)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="car-max-rate"
            v-model="carHourWeekRange1"
            label="Automóvil (De 4 a 30 hrs)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ carHourWeekRange1 })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(carHourWeekRange1)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="car-min-rate-work-week"
            v-model="carHourWeekRange2"
            label="Automóvil (Mas de 31 hrs)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ carHourWeekRange2 })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(carHourWeekRange2)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <hr />
      <p><span class="span-bold">Fin de semana </span>Horario: 9 am - 10 pm</p>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="motorcycle-max-rate-weekend"
            v-model="bikeHourWeekendRange1"
            label="Motocicleta (De 4 a 30 hrs)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ bikeHourWeekendRange1 })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(bikeHourWeekendRange1)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="motorcycle-min-rate-weekend"
            v-model="bikeHourWeekendRange2"
            label="Motocicleta (Mas de 31 hrs)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ bikeHourWeekendRange2 })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(bikeHourWeekendRange2)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="car-max-rate-weekend"
            v-model="carHourWeekendRange1"
            label="Automóvil (De 4 a 30 hrs)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ carHourWeekendRange1 })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(carHourWeekendRange1)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="car-min-rate-weekend"
            v-model="carHourWeekendRange2"
            label="Automóvil (Mas de 31 hrs)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ carHourWeekendRange2 })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(carHourWeekendRange2)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <hr />
      <p><span class="span-bold">Nocturna </span>Horario: 10 pm - 9 am</p>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="motorcycle-max-rate-night"
            v-model="bikeHourNighttime"
            label="Motocicleta"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ bikeHourNighttime })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(bikeHourNighttime)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="car-max-rate-night"
            v-model="carHourNighttime"
            label="Automóvil"
            :disabled="disableFields"
            prepend-text="$"
            @blur="formatNumberOnBlur({ carHourNighttime })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(carHourNighttime)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <hr />
      <z-button
        v-show="!editMode"
        class="mt-3"
        size="lg"
        @click="enableEditMode"
      >
        Editar
      </z-button>
      <z-button
        v-show="editMode"
        :loading="loading"
        class="mt-3"
        size="lg"
        @click="saveForm"
      >
        Guardar
      </z-button>
      <z-button
        v-show="editMode"
        class="mt-3 ml-3"
        variant="secondary"
        size="lg"
        :disabled="loading"
        @click="cancel"
      >
        Cancelar
      </z-button>
    </b-form>
  </collapsable-card>
</template>

<script>
import RatesLayout from "./RatesLayout.vue";
import Clients from "@/services/clients";
import RatesReservation from "@/services/rates-reservation";
import { numberFormatter } from "@/utils/strings";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import ratesUtils from "@/mixins/ratesUtils";

export default {
  name: "RatesReservations",

  components: {
    RatesLayout,
    CollapsableCard,
    ServiceTypeAvatar
  },

  mixins: [ratesUtils],

  props: {
    clientId: {
      type: String,
      required: true
    },
    serviceTypeNumber: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      editMode: false,
      rateId: null,
      backup: {},
      showRatesReservations: true,
      bikeHourNighttime: null,
      bikeHourWeekRange1: null,
      bikeHourWeekRange2: null,
      bikeHourWeekendRange1: null,
      bikeHourWeekendRange2: null,
      carHourNighttime: null,
      carHourWeekRange1: null,
      carHourWeekRange2: null,
      carHourWeekendRange1: null,
      carHourWeekendRange2: null
    };
  },

  computed: {
    disableFields() {
      return !this.editMode;
    }
  },

  beforeMount() {
    this.getRatesReservation();
  },

  methods: {
    getRatesReservation() {
      Clients.getRatesReservation(this.clientId)
        .then(res => {
          this.rateId = res.id;
          this.bikeHourNighttime = res.bikeHourNighttime;
          this.bikeHourWeekRange1 = res.bikeHourWeekRange1;
          this.bikeHourWeekRange2 = res.bikeHourWeekRange2;
          this.bikeHourWeekendRange1 = res.bikeHourWeekendRange1;
          this.bikeHourWeekendRange2 = res.bikeHourWeekendRange2;
          this.carHourNighttime = res.carHourNighttime;
          this.carHourWeekRange1 = res.carHourWeekRange1;
          this.carHourWeekRange2 = res.carHourWeekRange2;
          this.carHourWeekendRange1 = res.carHourWeekendRange1;
          this.carHourWeekendRange2 = res.carHourWeekendRange2;
        })
        .catch(error => {
          this.$captureError(error);
        });
    },
    cancel() {
      this.editMode = false;
      this.restoreForm();
    },
    enableEditMode() {
      this.editMode = true;
      this.backupForm();
    },
    backupForm() {
      this.backup.bikeHourNighttime = this.bikeHourNighttime;
      this.backup.bikeHourWeekRange1 = this.bikeHourWeekRange1;
      this.backup.bikeHourWeekRange2 = this.bikeHourWeekRange2;
      this.backup.bikeHourWeekendRange1 = this.bikeHourWeekendRange1;
      this.backup.bikeHourWeekendRange2 = this.bikeHourWeekendRange2;
      this.backup.carHourNighttime = this.carHourNighttime;
      this.backup.carHourWeekRange1 = this.carHourWeekRange1;
      this.backup.carHourWeekRange2 = this.carHourWeekRange2;
      this.backup.carHourWeekendRange1 = this.carHourWeekendRange1;
      this.backup.carHourWeekendRange2 = this.carHourWeekendRange2;
    },
    restoreForm() {
      this.bikeHourNighttime = this.backup.bikeHourNighttime;
      this.bikeHourWeekRange1 = this.backup.bikeHourWeekRange1;
      this.bikeHourWeekRange2 = this.backup.bikeHourWeekRange2;
      this.bikeHourWeekendRange1 = this.backup.bikeHourWeekendRange1;
      this.bikeHourWeekendRange2 = this.backup.bikeHourWeekendRange2;
      this.carHourNighttime = this.backup.carHourNighttime;
      this.carHourWeekRange1 = this.backup.carHourWeekRange1;
      this.carHourWeekRange2 = this.backup.carHourWeekRange2;
      this.carHourWeekendRange1 = this.backup.carHourWeekendRange1;
      this.carHourWeekendRange2 = this.backup.carHourWeekendRange2;
    },
    saveForm() {
      if (!this.rateId) {
        this.createRate();
      } else {
        this.editRate();
      }
    },
    createRate() {
      this.loading = true;
      RatesReservation.createRatesReservation(this.formatRequest())
        .then(res => {
          this.rateId = res.id;
          this.backupForm();
          this.cancel();
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editRate() {
      this.loading = true;
      RatesReservation.updateRatesReservation(this.rateId, this.formatRequest())
        .then(res => {
          this.backupForm();
          this.cancel();
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatRequest() {
      return {
        bikeHourNighttime: this.floatNumber(this.bikeHourNighttime),
        bikeHourWeekRange1: this.floatNumber(this.bikeHourWeekRange1),
        bikeHourWeekRange2: this.floatNumber(this.bikeHourWeekRange2),
        bikeHourWeekendRange1: this.floatNumber(this.bikeHourWeekendRange1),
        bikeHourWeekendRange2: this.floatNumber(this.bikeHourWeekendRange2),
        carHourNighttime: this.floatNumber(this.carHourNighttime),
        carHourWeekRange1: this.floatNumber(this.carHourWeekRange1),
        carHourWeekRange2: this.floatNumber(this.carHourWeekRange2),
        carHourWeekendRange1: this.floatNumber(this.carHourWeekendRange1),
        carHourWeekendRange2: this.floatNumber(this.carHourWeekendRange2),
        clientId: this.clientId
      };
    },
    numberFormatter(value) {
      if (value) {
        const parsedVal = parseFloat(this.removeCommas(value));
        return numberFormatter(parsedVal);
      }
      return "";
    },
    floatNumber(value) {
      if (typeof value === "string") {
        return parseFloat(this.removeCommas(value));
      }
      return value;
    },
    removeCommas(value) {
      return value.replace(/,/g, "");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/z-input-group.scss";
form hr:nth-child(1) {
  margin: 1em 0;
}

hr {
  border-color: $link-water;
  margin: 1.5em 0;
}
.span-bold {
  margin-right: 8px;
  font-weight: 600;
}
</style>
