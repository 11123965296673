<template>
  <div class="container-client-link-branch">
    <div class="d-flex flex-column flex-sm-row justify-content-between">
      <span class="card-title">
        Clientes
      </span>
      <div
        class="d-flex flex-column flex-sm-row justify-content-between mt-3 mt-sm-0"
      >
        <z-button
          class="mr-0 mr-sm-3"
          size="lg"
          variant="secondary"
          @click="$emit('hide')"
        >
          Cancelar
        </z-button>
        <z-button
          v-show="hasAddress || createAddress"
          size="lg"
          variant="primary"
          class="mt-3 mt-sm-0"
          :disabled="!hasAddress || emptySelection"
          @click="linkBranch"
        >
          Vincular sucursal
        </z-button>
        <z-button
          v-show="!hasAddress && !createAddress"
          size="lg"
          variant="primary"
          class="mt-3 mt-sm-0"
          :disabled="emptySelection"
          @click="createAddress = true"
        >
          Continuar
        </z-button>
      </div>
    </div>
    <div v-show="!createAddress" class="mt-4">
      <z-search-input
        v-model="searchText"
        placeholder="Busca por nombre o correo"
      />
    </div>
    <quick-message
      class="my-2"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="typeOfMessage"
    />
    <div v-show="!createAddress">
      <div class="mt-4">
        <z-table
          responsive="lg"
          hover
          primary-key="id"
          :items="clients"
          :fields="fields"
          :busy="loading"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @row-clicked="rowClicked"
        >
          <!-- slot - empty message -->
          <template v-slot:empty>
            <p class="text-center py-5 my-5 font-weight-semi-bold">
              No hay clientes disponibles para vincular
            </p>
          </template>

          <!-- slot - checkboxes -->
          <template v-slot:cell(check)="{ item: client }">
            <b-form-checkbox
              v-model="selectedClients"
              :value="client.id"
              class="checkbox"
              size="lg"
              @change="setSelectedClient"
            />
          </template>

          <!-- slot - status -->
          <template v-slot:cell(status)="data">
            <status-client :status="data.value" />
          </template>
        </z-table>
        <z-table-pagination
          :total-rows="totalRows"
          :per-page.sync="perPage"
          :current-page.sync="currentPage"
        />
      </div>
    </div>
    <form-default-address
      v-if="createAddress"
      class="mt-2"
      @address-change="handleAddressChange"
      @references-change="handleReferencesChange"
    />
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import pagination from "@/mixins/pagination";
import StatusClient from "@/app/components/StatusClient";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import FormDefaultAddress from "@/app/components/FormDefaultAddress";

export default {
  name: "ClientLinkBranch",

  components: {
    ZSearchInput,
    ZTable,
    ZTablePagination,
    StatusClient,
    FormDefaultAddress
  },

  mixins: [pagination],

  data() {
    return {
      loading: false,
      sortBy: "name",
      sortDesc: true,
      totalRows: 0,
      searchText: "",
      message: "",
      typeOfMessage: "info",
      isSuccess: false,
      hasErrors: false,
      createAddress: false,
      clients: [],
      selectedClients: [],
      draftAddress: null,
      draftReference: "",
      fields: [
        { key: "check", label: "" },
        { key: "name", label: "Nombre" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Teléfono" },
        {
          key: "status",
          label: "Estado"
        }
      ]
    };
  },

  computed: {
    hasMessage: {
      get() {
        return this.isSuccess || this.hasErrors;
      },
      set(val) {
        this.isSuccess = val;
        this.hasErrors = val;
      }
    },
    emptySelection() {
      return this.selectedClients.length === 0;
    },
    selectedClient() {
      if (this.selectedClients.length === 1) {
        return this.clients.find(c => c.id === this.selectedClients[0]);
      } else return null;
    },
    addressId() {
      return this.selectedClient?.addressDefault?.addressId || null;
    },
    hasAddress: {
      get() {
        if (this.selectedClient != null) {
          let client = this.selectedClient;
          return client?.addressDefault || false || this.hasCapturedAddress;
        } else {
          return this.emptySelection;
        }
      }
    },
    hasCapturedAddress() {
      return (
        this.draftAddress?.address != null &&
        this.draftAddress?.position != null &&
        this.draftAddress?.position?.lat != null &&
        this.draftAddress?.position?.lng != null
      );
    }
  },

  watch: {
    currentPage: "getClients",
    perPage: "getClientsAndResetPagination",
    searchText() {
      this.search();
    }
  },

  created() {
    this.getClients();
    this.search = _debounce(() => {
      this.getClientsAndResetPagination();
    }, 600);
  },

  methods: {
    rowClicked(item) {
      const id = item.id;
      this.selectedClients.push(id);
      if (this.selectedClients.length > 1) {
        this.selectedClients.shift();
      }
    },

    setSelectedClient(item) {
      if (item.id) {
        item = item.id;
      }
      if (item == null) {
        this.selectedClients = [];
      } else {
        if (this.selectedClients.length > 1) {
          this.selectedClients.shift();
        }
      }
    },

    getClientsAndResetPagination() {
      this.paginationReset();
      this.getClients();
    },

    paginationReset() {
      this.currentPage = 1;
    },

    getClients() {
      const { filter, where } = this.buildRequest();
      this.loading = true;
      this.$store
        .dispatch("clients/getBranchListing", { filter, where })
        .then(({ data, meta }) => {
          this.clients = data;
          if (meta.skip === 0) {
            this.totalRows = meta.count;
          }
        })
        .catch(err => {
          this.hasError = true;
          this.message = err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    buildRequest() {
      const filter = {
        ...this.filter,
        order: `${this.sortBy} DESC`
      };
      const where = { like: this.searchText, available: true };

      return { filter, where };
    },

    handleAddressChange(address) {
      this.draftAddress = { ...address };
    },

    handleReferencesChange(references) {
      this.draftReference = references;
    },

    linkBranch() {
      const data = {};
      if (this.hasAddress) {
        if (this.addressId != null) {
          data.addressId = this.addressId;
        } else {
          data.phone = this.selectedClient?.phone || undefined;
          data.address = this.draftAddress.address;
          data.position = this.draftAddress.position;
          data.information =
            this.draftReference != "" ? this.draftReference : undefined;
        }
      }
      data.branchId = this.selectedClient?.id || null;
      this.$store
        .dispatch("clients/addBranch", {
          companyId: this.$route.params.id,
          data
        })
        .then(res => {
          this.$emit("branch-linked", res);
          this.$emit("hide");
          this.createAddress = false;
        })
        .catch(err => {
          this.typeOfMessage = "error";
          if (err.message) {
            this.message = err.message;
          } else {
            this.message = `Error al vincular la sucursal a la compañia.`;
          }
          this.hasErrors = true;
          this.$captureError(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.container-client-link-branch {
  display: flex;
  flex-direction: column;
}
</style>
