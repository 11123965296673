<template>
  <collapsable-card :visible.sync="showRatesCrossDock">
    <template #header>
      <div class="font-weight-semi-bold d-flex align-items-center">
        <service-type-avatar :type="serviceTypeNumber" size="md" class="mr-3" />
        {{ title }}
      </div>
    </template>
    <b-form>
      <div v-if="isSameDay">
        <rates-layout>
          <template v-slot:left>
            <z-input
              id="rate-one-same-day"
              v-model="sdrange1"
              label="0 a 5 Kg"
              prepend-text="$"
              :disabled="disableFields"
              @blur="formatNumberOnBlur({ sdrange1 })"
            />
          </template>

          <template v-slot:right>
            <z-input
              :value="calculateIVA(sdrange1)"
              label="+ IVA"
              prepend-text="$"
              :disabled="true"
            />
          </template>
        </rates-layout>

        <rates-layout>
          <template v-slot:left>
            <z-input
              id="rate-two-same-day"
              v-model="sdrange2"
              label="6 a 10 Kg"
              prepend-text="$"
              :disabled="disableFields"
              @blur="formatNumberOnBlur({ sdrange2 })"
            />
          </template>

          <template v-slot:right>
            <z-input
              :value="calculateIVA(sdrange2)"
              label="+ IVA"
              prepend-text="$"
              :disabled="true"
            />
          </template>
        </rates-layout>

        <rates-layout>
          <template v-slot:left>
            <z-input
              id="rate-three-same-day"
              v-model="sdrange3"
              label="11 a 20 Kg"
              prepend-text="$"
              :disabled="disableFields"
              @blur="formatNumberOnBlur({ sdrange3 })"
            />
          </template>

          <template v-slot:right>
            <z-input
              :value="calculateIVA(sdrange3)"
              label="+ IVA"
              prepend-text="$"
              :disabled="true"
            />
          </template>
        </rates-layout>
      </div>

      <div v-else>
        <rates-layout>
          <template v-slot:left>
            <z-input
              id="rate-one-next-day"
              v-model="ndrange1"
              label="0 a 5 Kg"
              prepend-text="$"
              :disabled="disableFields"
              @blur="formatNumberOnBlur({ ndrange1 })"
            />
          </template>

          <template v-slot:right>
            <z-input
              :value="calculateIVA(ndrange1)"
              label="+ IVA"
              prepend-text="$"
              :disabled="true"
            />
          </template>
        </rates-layout>

        <rates-layout>
          <template v-slot:left>
            <z-input
              id="rate-two-next-day"
              v-model="ndrange2"
              label="6 a 10 Kg"
              prepend-text="$"
              :disabled="disableFields"
              @blur="formatNumberOnBlur({ ndrange2 })"
            />
          </template>

          <template v-slot:right>
            <z-input
              :value="calculateIVA(ndrange2)"
              label="+ IVA"
              prepend-text="$"
              :disabled="true"
            />
          </template>
        </rates-layout>

        <rates-layout>
          <template v-slot:left>
            <z-input
              id="rate-three-next-day"
              v-model="ndrange3"
              label="11 a 20 Kg"
              prepend-text="$"
              :disabled="disableFields"
              @blur="formatNumberOnBlur({ ndrange3 })"
            />
          </template>

          <template v-slot:right>
            <z-input
              :value="calculateIVA(ndrange3)"
              label="+ IVA"
              prepend-text="$"
              :disabled="true"
            />
          </template>
        </rates-layout>
      </div>
      <z-button
        v-show="!editMode"
        id="crossdock-edit-button"
        class="mt-3"
        size="lg"
        @click="enableEditMode"
      >
        Editar
      </z-button>
      <z-button
        v-show="editMode"
        :loading="loading"
        class="mt-3"
        size="lg"
        @click="saveForm"
      >
        Guardar
      </z-button>
      <z-button
        v-show="editMode"
        class="mt-3 ml-3"
        variant="secondary"
        size="lg"
        :disabled="loading"
        @click="cancel"
      >
        Cancelar
      </z-button>
    </b-form>
  </collapsable-card>
</template>

<script>
import RatesLayout from "./RatesLayout.vue";
import Clients from "@/services/clients";
import RatesCrossDock from "@/services/rates-crossdock";
import { numberFormatter } from "@/utils/strings";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import ratesUtils from "@/mixins/ratesUtils";

export default {
  name: "RatesCrossDock",

  components: { RatesLayout, ServiceTypeAvatar, CollapsableCard },

  mixins: [ratesUtils],

  props: {
    clientId: {
      type: String,
      required: true
    },
    isSameDay: {
      type: Boolean,
      default: false
    },
    serviceTypeNumber: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      editMode: false,
      sdrange1: null,
      sdrange2: null,
      sdrange3: null,
      ndrange1: null,
      ndrange2: null,
      ndrange3: null,
      crossDockId: null,
      backup: {},
      showRatesCrossDock: true
    };
  },

  computed: {
    disableFields() {
      return !this.editMode;
    },
    title() {
      return this.isSameDay ? "Guías Same day" : "Guías Next day";
    }
  },

  beforeMount() {
    this.getRatesCrossDock();
  },

  methods: {
    getRatesCrossDock() {
      Clients.getRatesCrossDock(this.clientId)
        .then(res => {
          this.sdrange1 = this.isSameDay ? res.sameDay.range_1 : null;
          this.sdrange2 = this.isSameDay ? res.sameDay.range_2 : null;
          this.sdrange3 = this.isSameDay ? res.sameDay.range_3 : null;
          this.ndrange1 = !this.isSameDay ? res.nextDay.range_1 : null;
          this.ndrange2 = !this.isSameDay ? res.nextDay.range_2 : null;
          this.ndrange3 = !this.isSameDay ? res.nextDay.range_3 : null;
          this.crossDockId = res.id;
          this.backupForm();
        })
        .catch(error => {
          this.$captureError(error);
        });
    },
    cancel() {
      this.editMode = false;
      this.restoreForm();
    },
    enableEditMode() {
      this.editMode = true;
      this.backupForm();
    },
    backupForm() {
      this.backup.sdrange1 = this.sdrange1;
      this.backup.sdrange2 = this.sdrange2;
      this.backup.sdrange3 = this.sdrange3;
      this.backup.ndrange1 = this.ndrange1;
      this.backup.ndrange2 = this.ndrange2;
      this.backup.ndrange3 = this.ndrange3;
    },

    restoreForm() {
      this.sdrange1 = this.backup.sdrange1;
      this.sdrange2 = this.backup.sdrange2;
      this.sdrange3 = this.backup.sdrange3;
      this.ndrange1 = this.backup.ndrange1;
      this.ndrange2 = this.backup.ndrange2;
      this.ndrange3 = this.backup.ndrange3;
    },
    saveForm() {
      if (!this.crossDockId) {
        this.createRate();
      } else {
        this.editRate();
      }
    },
    createRate() {
      this.loading = true;
      RatesCrossDock.createRatesCrossDock(this.formatRequest("create"))
        .then(res => {
          this.crossDockId = res.id;
          this.backupForm();
          this.cancel();
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editRate() {
      this.loading = true;
      RatesCrossDock.updateRatesCrossDock(
        this.crossDockId,
        this.formatRequest()
      )
        .then(res => {
          this.backupForm();
          this.cancel();
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    formatRequest(val) {
      if (val === "create") {
        return {
          sd_range_1: this.floatNumber(this.sdrange1),
          sd_range_2: this.floatNumber(this.sdrange2),
          sd_range_3: this.floatNumber(this.sdrange3),
          nd_range_1: this.floatNumber(this.ndrange1),
          nd_range_2: this.floatNumber(this.ndrange2),
          nd_range_3: this.floatNumber(this.ndrange3),
          clientId: this.clientId
        };
      } else {
        return {
          sd_range_1: this.floatNumber(this.sdrange1),
          sd_range_2: this.floatNumber(this.sdrange2),
          sd_range_3: this.floatNumber(this.sdrange3),
          nd_range_1: this.floatNumber(this.ndrange1),
          nd_range_2: this.floatNumber(this.ndrange2),
          nd_range_3: this.floatNumber(this.ndrange3)
        };
      }
    },
    numberFormatter(value) {
      if (value) {
        const parsedVal = parseFloat(this.removeCommas(value));
        return numberFormatter(parsedVal);
      }
      return "";
    },
    floatNumber(value) {
      if (typeof value === "string") {
        return parseFloat(this.removeCommas(value));
      }
      return value;
    },
    removeCommas(value) {
      return value.replace(/,/g, "");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/z-input-group.scss";
</style>
