<template>
  <div class="user-detail-rates">
    <b-row class="mb-3">
      <b-col cols="12">
        <quick-message
          class="mb-1"
          with-icon
          show
          message="Los precios de tarifas no incluyen IVA."
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6" class="columns-align">
        <rates-multi-point
          :client-id="clientId"
          :service-type-number="serviceType.NUM_SIMPLE"
        />
        <rates-packages
          :client-id="clientId"
          :service-type-number="serviceType.NUM_PACKAGE_DELIVERY"
        />
      </b-col>
      <b-col cols="12" lg="6" class="columns-align right-side">
        <rates-reservations
          :client-id="clientId"
          :service-type-number="serviceType.NUM_RENT_PER_HOUR"
        />

        <rates-cross-dock
          :client-id="clientId"
          is-same-day
          :service-type-number="serviceType.NUM_PARCEL_PICKUP"
        />

        <rates-cross-dock
          :client-id="clientId"
          :service-type-number="serviceType.NUM_PARCEL_PICKUP"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ServiceType from "@zubut/common/src/constants/services/type";
import RatesMultiPoint from "./RatesMultiPoint";
import RatesPackages from "./RatesPackages";
import RatesReservations from "./RatesReservations";
import RatesCrossDock from "./RatesCrossDock";

export default {
  name: "ClientDetailRates",

  components: {
    RatesMultiPoint,
    RatesPackages,
    RatesReservations,
    RatesCrossDock
  },

  data() {
    return {
      serviceType: ServiceType,
      clientId: this.$route.params.id
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/bootstrap/alert.scss";
$space-between-cards: 20px;

.columns-align {
  display: flex;
  flex-direction: column;
  gap: $space-between-cards;
}
@include media-breakpoint-down(md) {
  .right-side {
    margin-top: $space-between-cards;
  }
}
</style>
