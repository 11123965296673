<template>
  <b-row class="gap my-3">
    <b-col cols="12">
      <b-row>
        <b-col cols="8">
          <slot name="left"></slot>
        </b-col>
        <b-col cols="4">
          <slot name="right"></slot>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "RatesLayout"
};
</script>
<style lang="scss" scoped>
.gap {
  gap: 28px;
}
</style>
