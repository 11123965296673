import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("RatesPackages");

let RatesPackages = Object.assign(
  {},
  {
    relation: "",

    createRatesPackages(data) {
      return loopback.post(`/${this.model}`, data);
    },

    updateRatesPackages(id, data) {
      return loopback.put(`/${this.model}/${id}`, data);
    }
  },
  modelObject
);

export default RatesPackages;
