<template>
  <confirmation-dialog
    title="Modificar monto del Wallet"
    :show="show"
    @accept="verifyNewAmount"
    @cancel="hideModal"
    @hidden="hideModal"
  >
    <quick-message
      class="mb-3"
      with-icon
      :show.sync="hasErrors"
      :message="message"
      type="error"
    />
    <b-form-group label="Monto actual" label-for="amount">
      <b-form-input id="amount" :value="amount" readonly> </b-form-input>
    </b-form-group>
    <b-form-group
      label="¿Cuál quieres que sea el nuevo monto?"
      label-for="newAmount"
    >
      <b-input-group size="md" prepend="$" append="MXN">
        <b-form-input id="newAmount" v-model.trim="newAmount" placeholder="0">
        </b-form-input>
      </b-input-group>
    </b-form-group>
  </confirmation-dialog>
</template>

<script>
import ConfirmationDialog from "@/app/components/ConfirmationDialog";

export default {
  name: "ModifyWalletAmount",

  components: {
    ConfirmationDialog
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    selectedWallet: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      newAmount: "",
      message: "",
      hasErrors: false
    };
  },

  computed: {
    amount() {
      if (this.selectedWallet.amount) {
        return `$ ${this.selectedWallet.amount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MXN`;
      }
      return 0;
    }
  },

  methods: {
    verifyNewAmount() {
      if (
        !isNaN(this.newAmount) &&
        this.newAmount !== "" &&
        this.newAmount >= 0
      ) {
        this.$emit("update:show", false);
        this.$store
          .dispatch("request/modifyUserWallet", {
            amount: parseFloat(this.newAmount) * 100,
            walletId: this.selectedWallet.id
          })
          .then(res => {
            this.$emit("updated-amount", res.amount);
          })
          .catch(error => {
            this.$captureError(error);
            this.hasErrors = true;
            this.message = "Se ha producido un error al modificar el monto";
          });
      } else {
        this.hasErrors = true;
        this.message = "Verifica que el nuevo monto sea válido";
      }
    },

    hideModal() {
      this.newAmount = "";
      this.$emit("update:show", false);
    }
  }
};
</script>
