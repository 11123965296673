import VehicleTypes from "@/constants/vehicles/type";

const DELIVERED_MOTORCYCLE = 13.4; // 15.54 with 16% IVA.
const ATTEMPT_MOTORCYCLE = 6.7; // 7.77 with 16% IVA.

const DELIVERED_CAR = null;
const ATTEMPT_CAR = null;

const get = {};
get[VehicleTypes.NUM_MOTORCYCLE] = {};
get[VehicleTypes.NUM_MOTORCYCLE].DELIVERED = DELIVERED_MOTORCYCLE;
get[VehicleTypes.NUM_MOTORCYCLE].ATTEMPT = ATTEMPT_MOTORCYCLE;

get[VehicleTypes.NUM_CAR] = {};
get[VehicleTypes.NUM_CAR].DELIVERED = DELIVERED_CAR;
get[VehicleTypes.NUM_CAR].ATTEMPT = ATTEMPT_CAR;

export default {
  get,
  DELIVERED_MOTORCYCLE,
  ATTEMPT_MOTORCYCLE,
  DELIVERED_CAR,
  ATTEMPT_CAR
};
