<template>
  <collapsable-card :visible.sync="showRatesMultiPoint">
    <template #header>
      <div class="font-weight-semi-bold d-flex align-items-center">
        <service-type-avatar :type="serviceTypeNumber" size="md" class="mr-3" />
        Entregas express
      </div>
    </template>
    <b-form>
      <rates-layout>
        <template v-slot:left>
          <z-input
            id="on-demand-minimun-rate"
            v-model="base"
            label="Mínimo"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ base })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(base)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="on-demand-motorcycle-rate"
            v-model="bikeKm"
            label="Motocicleta (Costo por KM)"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ bikeKm })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(bikeKm)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="on-demand-car-rate"
            v-model="carKm"
            label="Automóvil (Costo por KM)"
            :disabled="disableFields"
            prepend-text="$"
            @blur="formatNumberOnBlur({ carKm })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(carKm)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="on-demand-bicycle-rate"
            v-model="ecoKm"
            label="Bicicleta (Costo por KM)"
            :disabled="disableFields"
            prepend-text="$"
            @blur="formatNumberOnBlur({ ecoKm })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(ecoKm)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="on-demand-destination-rate"
            v-model="destination"
            label="Costo por destino"
            :disabled="disableFields"
            prepend-text="$"
            @blur="formatNumberOnBlur({ destination })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(destination)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <rates-layout>
        <template v-slot:left>
          <z-input
            id="on-demand-fixed-rate"
            v-model="fixed"
            label="Costo fijo"
            prepend-text="$"
            :disabled="disableFields"
            @blur="formatNumberOnBlur({ fixed })"
          />
        </template>

        <template v-slot:right>
          <z-input
            :value="calculateIVA(fixed)"
            label="+ IVA"
            prepend-text="$"
            :disabled="true"
          />
        </template>
      </rates-layout>

      <z-button
        v-show="!editMode"
        id="on-demand-edit-button"
        class="mt-3"
        size="lg"
        @click="enableEditMode"
      >
        Editar
      </z-button>
      <z-button
        v-show="editMode"
        :loading="loading"
        class="mt-3"
        size="lg"
        @click="saveForm"
      >
        Guardar
      </z-button>
      <z-button
        v-show="editMode"
        class="mt-3 ml-3"
        variant="secondary"
        size="lg"
        :disabled="loading"
        @click="cancel"
      >
        Cancelar
      </z-button>
    </b-form>
  </collapsable-card>
</template>

<script>
import RatesLayout from "./RatesLayout.vue";
import CollapsableCard from "@zubut/common/src/components/CollapsableCard";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import Clients from "@/services/clients";
import RatesOnDemand from "@/services/rates-on-demand";
import ConsRatesOnDemand from "@/constants/rates/on-demand";
import { numberFormatter } from "@/utils/strings";
import ratesUtils from "@/mixins/ratesUtils";

export default {
  name: "RatesMultiPoint",

  components: { RatesLayout, CollapsableCard, ServiceTypeAvatar },

  mixins: [ratesUtils],

  props: {
    clientId: {
      type: String,
      required: true
    },
    serviceTypeNumber: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      editMode: false,
      rateId: null,
      base: null,
      bikeKm: null,
      carKm: null,
      ecoKm: null,
      destination: null,
      fixed: null,
      backup: {},
      showRatesMultiPoint: true
    };
  },

  computed: {
    disableFields() {
      return !this.editMode;
    }
  },

  beforeMount() {
    this.getRatesOnDemand();
  },

  methods: {
    getRatesOnDemand() {
      Clients.getRatesOnDemand(this.clientId)
        .then(res => {
          this.rateId = res.id;
          this.base = res.base;
          this.bikeKm = res.bikeKm;
          this.carKm = res.carKm;
          this.destination = res.destination;
          this.ecoKm = res.ecoKm;
          this.fixed = res.fixed;
        })
        .catch(error => {
          if (error.statusCode === 404) {
            this.setDefaultValues();
          } else {
            this.$captureError(error);
          }
        });
    },
    setDefaultValues() {
      this.base = ConsRatesOnDemand.BASE;
      this.bikeKm = ConsRatesOnDemand.KM_MOTORCYCLE;
      this.carKm = ConsRatesOnDemand.KM_CAR;
      this.ecoKm = ConsRatesOnDemand.KM_ECO;
      this.destination = ConsRatesOnDemand.DESTINATION;
      this.fixed = null;
    },
    cancel() {
      this.editMode = false;
      this.restoreForm();
    },
    enableEditMode() {
      this.editMode = true;
      this.backupForm();
    },
    backupForm() {
      this.backup.base = this.base;
      this.backup.bikeKm = this.bikeKm;
      this.backup.carKm = this.carKm;
      this.backup.ecoKm = this.ecoKm;
      this.backup.destination = this.destination;
      this.backup.fixed = this.fixed;
    },
    restoreForm() {
      this.base = this.backup.base;
      this.bikeKm = this.backup.bikeKm;
      this.carKm = this.backup.carKm;
      this.ecoKm = this.backup.ecoKm;
      this.destination = this.backup.destination;
      this.fixed = this.backup.fixed;
    },
    saveForm() {
      if (!this.rateId) {
        this.createRate();
      } else {
        this.editRate();
      }
    },
    createRate() {
      this.loading = true;
      RatesOnDemand.createRatesOndemand(this.formatRequest())
        .then(res => {
          this.rateId = res.id;
          this.backupForm();
          this.cancel();
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editRate() {
      this.loading = true;
      RatesOnDemand.updateRatesOndemand(this.rateId, this.formatRequest())
        .then(res => {
          this.backupForm();
          this.cancel();
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatRequest() {
      return {
        base: this.floatNumber(this.base),
        bikeKm: this.floatNumber(this.bikeKm),
        carKm: this.floatNumber(this.carKm),
        ecoKm: this.floatNumber(this.ecoKm),
        destination: this.floatNumber(this.destination),
        fixed: this.floatNumber(this.fixed),
        clientId: this.clientId
      };
    },
    numberFormatter(value) {
      if (value) {
        const parsedVal = parseFloat(this.removeCommas(value));
        return numberFormatter(parsedVal);
      }
      return "";
    },
    floatNumber(value) {
      if (typeof value === "string") {
        return parseFloat(this.removeCommas(value));
      }
      return value;
    },
    removeCommas(value) {
      return value.replace(/,/g, "");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/z-input-group.scss";
</style>
