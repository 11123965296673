import VehicleTypes from "@/constants/vehicles/type";

const BASE = 34.5; // 40 with 16% IVA.
const KM_MOTORCYCLE = 7.76; // 9 with 16% IVA.
const KM_CAR = 12.93; // 15 with 16% IVA.,
const KM_ECO = null; // TVD
const DESTINATION = 8.62; // 10 with 16% IVA.

const get = {};
get[VehicleTypes.NUM_MOTORCYCLE] = KM_MOTORCYCLE;
get[VehicleTypes.NUM_CAR] = KM_CAR;
get[VehicleTypes.NUM_ECO] = KM_ECO;

export default {
  get,
  BASE,
  KM_MOTORCYCLE,
  KM_CAR,
  KM_ECO,
  DESTINATION
};
