<template lang="html">
  <b-card class="p-2">
    <go-back
      v-show="!isLoading && user"
      :title="title"
      :subtitle="subtitle"
      @back-action="$router.go(-1)"
    >
      <template #end>
        <buttons-layout>
          <activity-button :id="id" type="client" />
        </buttons-layout>
      </template>
    </go-back>
    <div>
      <div v-if="isLoading && !user">
        <loading-spinner />
      </div>
      <div v-else class="py-4">
        <z-tabs pills content-class="mt-3 p-0">
          <z-tab
            lazy
            title="Perfil"
            :active="!$route.query.tab || $route.query.tab === 'perfil'"
            @click="changeTab('perfil')"
          >
            <loading-spinner v-if="isLoading" />
            <client-detail-profile v-else :user="user" />
          </z-tab>
          <z-tab
            v-if="isCompany"
            lazy
            title="Sucursales"
            :active="$route.query.tab === 'sucursales'"
            @click="changeTab('sucursales')"
          >
            <client-branches :client-id="id" />
          </z-tab>
          <z-tab
            lazy
            title="Tarifas"
            :active="$route.query.tab === 'tarifas'"
            @click="changeTab('tarifas')"
          >
            <client-detail-rates />
          </z-tab>
          <z-tab
            lazy
            title="Facturación"
            :active="$route.query.tab === 'facturacion'"
            @click="changeTab('facturacion')"
          >
            <loading-spinner v-if="isLoading" />
            <card-detail-billing-data
              v-else
              component="user"
              :client-id="id"
              :additional="additional"
              :user-email="email"
              @updated-additional="handleAdditionalUpdate"
            />
          </z-tab>
          <z-tab
            v-if="
              $can(clientAccess.READ_CLIENT_SERVICES, clientAccess.moduleName)
            "
            lazy
            title="Entregas"
            :active="$route.query.tab === 'entregas'"
            @click="changeTab('entregas')"
          >
            <client-detail-services />
          </z-tab>
          <z-tab
            lazy
            title="Admin"
            :active="$route.query.tab === 'admin'"
            @click="changeTab('admin')"
          >
            <loading-spinner v-if="isLoading" />
            <client-detail-admin
              v-else
              :user="user"
              @updated="handleAdminUpdate"
            />
          </z-tab>
        </z-tabs>
      </div>
    </div>
  </b-card>
</template>

<script>
import { formatISO } from "@zubut/common/src/utils/time";
import ClientAccess from "@/constants/access/clients";
import Type from "@zubut/common/src/constants/clients/type";
import lazyLoad from "@/app/components/lazyLoad";
import ActivityButton from "@/app/components/ActivityButton";
import GoBack from "@zubut/common/src/components/GoBackTitle";
import CardDetailBillingData from "@/app/components/DetailProfile/CardDetailBillingData";
import Clients from "@/services/clients";
import notifyMixin from "@/mixins/notify";
import cloneDeep from "lodash/cloneDeep";
import ClientDetailProfile from "./ClientDetailProfile";
import ClientDetailRates from "./ClientDetailRates/ClientDetailRates";
import ClientBranches from "./ClientBranches";
import Http from "@zubut/common/src/constants/http";
import ButtonsLayout from "@/app/layouts/ButtonsLayout.vue";
import ZTabs from "@zubut/common/src/components/ZTabs.vue";
import ZTab from "@zubut/common/src/components/ZTab.vue";

/* Lazy loaded components */
const ClientDetailServices = () => lazyLoad(import("./ClientDetailServices"));
const ClientDetailAdmin = () => lazyLoad(import("./ClientDetailAdmin"));

export default {
  name: "ClientDetail",

  components: {
    ClientDetailAdmin,
    CardDetailBillingData,
    ClientDetailRates,
    ClientDetailServices,
    ClientDetailProfile,
    ClientBranches,
    GoBack,
    ActivityButton,
    ButtonsLayout,
    ZTabs,
    ZTab
  },

  mixins: [notifyMixin("notifications")],

  data() {
    return {
      clientAccess: ClientAccess,
      user: null,
      rate: 0,
      isLoading: true,
      isChangingStatus: false
    };
  },

  computed: {
    adminUser() {
      return this.$store.state.auth.user;
    },
    title() {
      return this.user?.name ?? "Usuario";
    },
    subtitle() {
      const user = this.user;
      if (user && user.lastLogin) {
        return `Último inicio de sesión ${formatISO(user.lastLogin)}`;
      }
      return "No existe última sesión";
    },
    isCompany() {
      const type = this.user?.type || 0;
      return type === Type.NUM_COMPANY;
    },
    id() {
      return this.$route.params.id;
    },
    billingData() {
      return this.user?.billingData;
    },
    additional() {
      return this.user?.additional;
    },
    email() {
      return this.user?.email;
    }
  },

  watch: {
    // This watch is to update client data when companie select one of his branches
    $route(newRoute, oldRoute) {
      if (newRoute.params.id !== oldRoute.params.id) {
        this.getUser();
      }
    }
  },

  beforeMount() {
    this.getUser();
  },

  methods: {
    changeTab(tab) {
      this.$router
        .replace({ name: "clientDetail", query: { tab } })
        .catch(error => {
          this.$captureError(error);
        });
    },

    getUser() {
      this.isLoading = true;
      const clientId = this.$route.params.id;
      const filter = {
        include: [
          "billingData",
          { relation: "account" },
          { relation: "addresses", scope: { where: { defaultOrigin: true } } },
          "ratings"
        ]
      };
      Clients.findById({
        id: clientId,
        filter
      })
        .then(user => {
          this.user = { ...user };

          if (!user.distinguished) {
            this.user.distinguished = false;
          }

          const accountBillingData =
            this.user.account && this.user.account.length > 0
              ? this.user.account[0].billingData
              : null;
          this.user.billingData =
            this.user.billingData || accountBillingData || undefined;

          if (this.user.alias !== "") {
            this.user.alias = this.user.alias;
          } else {
            this.alias = "N/A";
          }
          this.isLoading = false;
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.$store.commit("setDisplayNotFound", true);
          } else {
            this.$captureError(err);
            this.isLoading = false;
          }
        });
    },

    handleAdminUpdate(user) {
      this.user = cloneDeep(user);
    },

    handleAdditionalUpdate(additional) {
      this.user.additional = cloneDeep(additional);
    }
  }
};
</script>
