<template>
  <div class="edit-container d-flex flex-column h-100 justify-content-around">
    <b-form-group
      id="fieldset-address"
      label="Dirección"
      label-for="address"
      label-size="sm"
      class="m-label"
    >
      <address-autocomplete
        class="form-control"
        :address="draftAddress"
        placeholder="Calle, No. Int/Ext. Colonia"
        select-first-on-enter
        name="address"
        type="text"
        required
        @address-change="handleAddressChange"
      />
    </b-form-group>
    <b-form-group
      id="fieldset-references"
      label="Referencias"
      label-for="references"
      label-size="sm"
      class="m-label"
    >
      <b-form-input
        id="references"
        v-model="draftReference"
        placeholder="Cruces, edificios, etc."
        trim
      />
    </b-form-group>
    <div class="mt-4">
      <z-map
        id="address-map"
        reverse-geocode
        :destinations="mapAddress"
        :center="innerMapCenter"
        @decoded-position="handleAddressChange"
      />
    </div>
  </div>
</template>

<script>
import AddressAutocomplete from "@/app/components/AddressAutocomplete";
import ZMap from "@zubut/common/src/components/ZMap";

export default {
  name: "FormDefaultAddress",
  components: {
    AddressAutocomplete,
    ZMap
  },
  props: {
    position: {
      type: Object,
      default: null
    },
    defaultAddress: {
      type: Object,
      default: null
    },
    address: {
      type: [String, Object],
      default: null
    },
    reference: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      draftAddress: this.address || null,
      draftReference: this.reference || ""
    };
  },
  computed: {
    innerMapCenter() {
      return [
        this.position?.lat ?? 20.6737777,
        this.position?.lng ?? -103.4054536
      ];
    },
    mapAddress() {
      if (this.draftAddress != null) {
        return this.draftAddress?.position ? [this.draftAddress] : [];
      }
      if (this.defaultAddress != null) {
        return this.defaultAddress ? [this.defaultAddress] : [];
      }
      return [];
    }
  },
  watch: {
    draftReference(newVal) {
      this.$emit("references-change", newVal);
    }
  },
  methods: {
    handleAddressChange(address) {
      this.$emit("address-change", address);
      this.draftAddress = { ...address };
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-container {
  ::v-deep .form-control.is-valid {
    background-image: none;
  }

  .m-label {
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 4px;
  }

  input {
    max-width: 460px;
    border: 1px solid $gainsboro;
  }

  ::v-deep .form-control::placeholder {
    font-size: 12px;
    color: $dim-gray;
  }
}

#address-map {
  height: 200px;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
}
</style>
