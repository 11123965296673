import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("RatesOndemand");

let RatesOndemand = Object.assign(
  {},
  {
    relation: "",

    createRatesOndemand(data) {
      return loopback.post(`/${this.model}`, data);
    },

    updateRatesOndemand(id, data) {
      return loopback.put(`/${this.model}/${id}`, data);
    }
  },
  modelObject
);

export default RatesOndemand;
