<template>
  <div class="container-client-branches">
    <div class="mt-4 d-flex flex-column flex-sm-row justify-content-between">
      <z-search-input
        v-model="searchText"
        class="search-input"
        placeholder="Busca por nombre o correo"
      />
      <b-dropdown
        class="mt-3 mt-sm-0"
        menu-class="branch-dropdown"
        variant="link"
        no-caret
      >
        <template v-slot:button-content>
          <z-button class="w-100 " variant="primary">
            Añadir sucursal
          </z-button>
        </template>
        <b-dropdown-item @click="showCreateClient">
          Crear sucursal
        </b-dropdown-item>
        <b-dropdown-item @click="showLinkClient">
          Vincular sucursal
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="mt-4">
      <quick-message
        class="mb-2"
        with-icon
        :show.sync="hasMessage"
        :message="message"
        :type="typeOfMessage"
      />
      <z-table
        responsive="lg"
        hover
        primary-key="id"
        :items="branches"
        :fields="fields"
        :busy="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @row-clicked="viewClientDetail"
      >
        <!-- slot - empty message -->
        <template v-slot:empty>
          <p class="text-center py-5 my-5 font-weight-semi-bold">
            No hay sucursales vinculadas a la compañia
          </p>
        </template>
        <!-- slot - status -->
        <template v-slot:cell(status)="data">
          <status-client :status="data.value" />
        </template>
      </z-table>
      <z-table-pagination
        :total-rows="totalRows"
        :per-page.sync="perPage"
        :current-page.sync="currentPage"
      />
    </div>
    <client-link-modal
      :show.sync="showLinkBranchModal"
      @branch-linked="handleBranchLinked"
    />
    <client-create-modal
      :show.sync="showCreateBranchModal"
      :parent-company="clientId"
      @branch-created="handleBranchLinked"
    />
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import ScreensMixin from "@/mixins/screen";
import pagination from "@/mixins/pagination";
import ClientAccess from "@/constants/access/clients";
import StatusClient from "@/app/components/StatusClient";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ClientLinkModal from "@/app/views/ClientDetail/ClientLinkBranch/ClientLinkBranchDesktop";
import ClientCreateModal from "@/app/views/ClientDetail/ClientCreateBranch/ClientCreateBranchDesktop";

export default {
  name: "ClientBranches",

  components: {
    ZSearchInput,
    ZTable,
    ZTablePagination,
    StatusClient,
    ClientLinkModal,
    ClientCreateModal
  },

  mixins: [pagination, ScreensMixin],

  props: {
    clientId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      showLinkBranchModal: false,
      showCreateBranchModal: false,
      sortBy: "name",
      sortDesc: true,
      totalRows: 0,
      searchText: "",
      message: "",
      typeOfMessage: "info",
      isSuccessful: false,
      hasError: false,
      branches: [],
      fields: [
        { key: "name", label: "Nombre" },
        { key: "phone", label: "Teléfono" },
        { key: "email", label: "Email" },

        {
          key: "addressDefault.address",
          label: "Dirección"
        },
        {
          key: "status",
          label: "Estado"
        }
      ]
    };
  },

  computed: {
    hasMessage: {
      get() {
        return this.isSuccessful || this.hasError;
      },
      set(val) {
        this.isSuccessful = val;
        this.hasError = val;
      }
    }
  },

  watch: {
    currentPage: "getBranches",
    perPage: "getBranchesAndResetPagination",
    searchText() {
      this.search();
    }
  },

  created() {
    this.search = _debounce(() => {
      this.getBranchesAndResetPagination();
    }, 600);
  },

  mounted() {
    this.getBranches();
  },

  methods: {
    getBranchesAndResetPagination() {
      this.paginationReset();
      this.getBranches();
    },

    paginationReset() {
      this.currentPage = 1;
    },

    getBranches() {
      const { filter, where } = this.buildRequest();
      this.loading = true;
      this.$store
        .dispatch("clients/getBranchListing", { filter, where })
        .then(({ data, meta }) => {
          this.branches = data;
          if (meta.skip === 0) {
            this.totalRows = meta.count;
          }
        })
        .catch(err => {
          this.hasError = true;
          this.message = err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    buildRequest() {
      const filter = {
        ...this.filter,
        order: `${this.sortBy} DESC`
      };
      const where = { like: this.searchText, clientId: this.clientId };

      return { filter, where };
    },

    viewClientDetail(item) {
      const id = item.id;
      if (this.$can(ClientAccess.READ_CLIENT_DETAIL, ClientAccess.moduleName)) {
        this.$router.push({ name: "clientDetail", params: { id } });
      }
    },

    showLinkClient() {
      if (this.isMobile()) {
        this.$router.push({ name: "linkBranch" });
      } else {
        this.showLinkBranchModal = true;
      }
    },

    showCreateClient() {
      if (this.isMobile()) {
        this.$router.push({ name: "createBranch" });
      } else {
        this.showCreateBranchModal = true;
      }
    },

    handleBranchLinked(address) {
      this.message = "Se vinculó la sucursal de manera exitosa";
      this.typeOfMessage = "success";
      this.isSuccessful = true;
      this.branches.push(address);
    }
  }
};
</script>

<style lang="scss" scoped>
.container-client-branches {
  ::v-deep .dropdown-toggle {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dim-gray;
    text-align: left;
    background-color: transparent;
    border-color: transparent;
    border-radius: 2px;
    font-weight: 500;
    min-height: 32px;
    padding: 0;

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: transparent;
    }

    &:visited {
      color: $nero;
    }

    &::after {
      font-size: 1.4em;
      color: $nero;
    }
  }

  ::v-deep .dropdown-menu {
    padding-left: 0.5em;
    padding-right: 0.5em;
    border: none;
    box-shadow: 0 3px 9px 1px rgba($tangaroa, 0.2);

    .dropdown-item {
      padding-top: 0.5em;
      padding-bottom: 0.5em;

      &:hover,
      &.active,
      &:active,
      &:focus {
        color: $nero;
        background-color: rgba($royal-blue, 0.06);
      }

      &:focus {
        background-color: rgba($royal-blue, 0.096);
        outline: none;
      }
    }
  }
}

@media only screen and (min-width: 550px) {
  .container-client-branches {
    .search-input {
      min-width: 350px;
    }
  }
}
</style>
