<template>
  <div class="client-detail-profile align-columns">
    <div class="left-side gap align-columns">
      <div class="card p-4 rounded-lg info">
        <card-detail-info
          component="clients"
          :business-line="user.businessLine"
          :name.sync="user.name"
          :contact.sync="user.contact"
          :type="user.type"
          :phone.sync="user.phone"
          :email.sync="user.email"
        />
      </div>
      <div
        v-if="$can(clientAccess.RESET_PASSWORD, clientAccess.moduleName)"
        class="card p-4 rounded-lg pass"
      >
        <card-detail-password component="user" />
      </div>
    </div>

    <div class="right-side gap align-columns">
      <client-wallet :user="user"></client-wallet>

      <div
        v-if="isBranch && hasDefaultAddress"
        class="card p-4 rounded-lg address"
        :class="{ 'expand-card': cardExpanded }"
      >
        <card-detail-address
          :user-address.sync="defaultOrigin"
          @expanded="handleCardExpanded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ClientAccess from "@/constants/access/clients";
import CardDetailInfo from "@/app/components/DetailProfile/CardDetailInfo";
import CardDetailPassword from "@/app/components/DetailProfile/CardDetailPassword";
import CardDetailAddress from "@/app/components/DetailProfile/CardDetailAddress";
import ClientType from "@zubut/common/src/constants/clients/type";
import ClientWallet from "./ClientWallet";

export default {
  name: "ClientDetailProfile",
  components: {
    CardDetailInfo,
    CardDetailPassword,
    CardDetailAddress,
    ClientWallet
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      clientAccess: ClientAccess,
      cardExpanded: false
    };
  },
  computed: {
    isBranch() {
      return this.user.type === ClientType.NUM_BRANCH;
    },

    defaultOrigin: {
      get() {
        if (!this.isBranch) return null;
        return this.user.addresses[0] ?? null;
      },
      set(val) {
        this.user.addresses = [val];
      }
    },

    hasDefaultAddress() {
      return this.defaultOrigin != null;
    }
  },
  methods: {
    handleCardExpanded(isExpanded) {
      this.cardExpanded = isExpanded;
    }
  }
};
</script>

<style lang="scss" scoped>
$gap: 16px;

.card {
  color: $black;
  border: 1px solid $gainsboro;
  margin: 0;
}

.gap {
  gap: $gap;
}
.client-detail-profile {
  gap: $gap;
}
.align-columns {
  display: flex;
  flex-direction: column;
}
.left-side {
  flex-grow: 1;
}
.right-side {
  flex-grow: 1.5;
}

@media only screen and (min-width: 768px) {
  .client-detail-profile {
    flex-direction: row;
    gap: 24px;
  }
}
</style>
