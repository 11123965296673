import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("RatesReservation");

let RatesReservation = Object.assign(
  {},
  {
    relation: "",

    createRatesReservation(data) {
      return loopback.post(`/${this.model}`, data);
    },

    updateRatesReservation(id, data) {
      return loopback.put(`/${this.model}/${id}`, data);
    }
  },
  modelObject
);

export default RatesReservation;
