<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import UserStatus from "@/constants/clients/status";
import StatusBadge from "@zubut/common/src/components/StatusBadge";

export default {
  name: "StatusClient",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      required: false,
      default: -1
    }
  },
  computed: {
    statusName() {
      return this.getStatusName(this.status);
    },
    colorVariant() {
      return this.getStatusColorVariant(this.status);
    }
  },

  methods: {
    getStatusName(status) {
      return UserStatus.get[status];
    },

    getStatusColorVariant(status) {
      if (status === UserStatus.NUM_ACTIVE) {
        return "green";
      }
      if (status === UserStatus.NUM_BLOCKED) {
        return "red";
      }
      if (status === UserStatus.NUM_PENDING) {
        return "blue";
      }
      if (status === UserStatus.NUM_NEW) {
        return "yellow";
      }
      return "green";
    }
  }
};
</script>
